import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Flex from '../components/Flex'
import Image from '../components/Image'

const propTypes = {
  data: PropTypes.object,
}

const Leistungen = ({ data }) => (
  <Layout>
    <SEO title="Leistungen" />

    <Container smallplus>
      <p sx={{ mb: 2 }}>Leistungen</p>
      <h1 sx={{ hyphens: `manual` }}>
        Unsere Kanzlei hilft Ihnen auf folgenden Gebieten des Strafrechts
      </h1>
    </Container>

    <Flex
      large
      sx={{
        flexDirection: `column`,
        p: 0,
        height: [`240px`, `320px`, `400px`, `400px`],
      }}
    >
      <Image
        fluid={data.image01.childImageSharp.gatsbyImageData}
        sx={{
          bg: `gray.5`,
          height: [`240px`, `320px`, `400px`, `400px`],
        }}
      />
    </Flex>

    <Container sx={{ pb: 0 }}>
      <ul>
        <li>
          Allgemeines Strafrecht (Mord, Totschlag, Raub, Körperverletzung, Brandstiftung, Einbruch,
          Diebstahl, Nötigung, Bedrohung, Beleidigung etc.)
        </li>
        <li>Betäubungsmittelstrafrecht (Drogenbesitz, Drogenhandel)</li>
        <li>Jugendstrafrecht</li>
        <li>
          Sexualstrafrecht (sexuelle Nötigung, sexueller Missbrauch, pornografische Schriften,
          Vergewaltigung, Zuhälterei etc.)
        </li>
        <li>
          Verkehrsstrafrecht (Bußgelder, Entzug der Fahrerlaubnis, Fahren ohne Fahrerlaubnis,
          Fahrerflucht, Trunkenheitsfahrten etc.)
        </li>
        <li>Wirtschaftsstrafrecht / Steuerstrafrecht</li>
        <li>Medizinstrafrecht</li>
        <li>Verstöße gegen das Waffenrecht</li>
        <li>Strafverfahrensrecht</li>
        <li>Strafvollstreckung</li>
        <li>Nebenklagevertretung</li>
        <li>Geschädigtenvertretung / Opfervertretung</li>
        <li>Zeugenbeistand / Zeugenschutz / Aussagedelikte</li>
        <li>Rechtsmittelverfahren: Beschwerde, Berufung, Revision</li>
      </ul>
    </Container>

    <Container>
      <p>
        Meine Aufgabe sehe ich darin, mit Ihnen die bestmögliche Strategie für Ihre persönliche
        Situation zu erarbeiten und diese erfolgreich umzusetzen.
      </p>
      <p>
        Am besten kontaktieren Sie uns bereits vor Einleitung eines Ermittlungsverfahrens. So werden
        Sie davor bewahrt, falsche Entscheidungen zu treffen und rechtliche Nachteile zu erleiden.
        Denn: Einmal gemachte Fehler lassen sich später mitunter nur schwer korrigieren.
      </p>
      <p>
        Selbstverständlich berate und vertrete ich Sie auch während des Hauptverfahrens, wenn nötig
        bis hin zu etwaigen Rechtsmittelverfahren (Berufung, Revision etc.). Auch die Betreuung bei
        etwaigen Strafvollstreckungsthemen bzw. während des Strafvollzuges wird gewährleistet.
      </p>
      <p>
        Als Fachanwältin für Strafrecht verfüge ich über eine besondere fachliche Qualifikation,
        stehe Ihnen in dieser schwierigen Situation zur Seite und verhelfe Ihnen zu Ihrem Recht.
      </p>
    </Container>
  </Layout>
)

Leistungen.propTypes = propTypes

export default Leistungen

export const query = graphql`{
  image01: file(relativePath: {eq: "rav/chris-liverani-9cd8qOgeNIY-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
}
`
